import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <p>
        Uh oh! Something went wrong. Go back <Link to="/">home</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
